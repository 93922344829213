import React, {useEffect, useState} from 'react';
import {
  HeaderContainer,
  NavContainer,
  NavList,
  NavElement,
  NavLink,
  SearchIcon,
  NavLogo,
  LogoContainer,
  ControlsContainer,
  ThemeIcon,
  Language,
  Separator,
  MobileHeaderContainer,
  MobileMenuWrapper,
  MobileMenuLink,
  MobileMenuButton,
  ArrowDown,
  ArrowUp,
  MobileSubMenu,
  MobileSubMenuLink,
} from './styles';
import Logo from '../../../images/logo.svg';
import Search from '../../../images/search.svg';
import WheelChair from '../../../images/wheelchair.svg';
import Lottie from 'react-lottie';
import * as animationData from './anim_logo_colors.json';
import HamburgerMenu from 'react-hamburger-menu';
import CategoryArts from './CategoryArts';
import CategoryArtist from './CategoryArtist';
import CategoryAbout from './CategoryAbout';
import {graphql, StaticQuery} from 'gatsby';

const defaultOptions = {
  loop: false,
  autoplay: false,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Header = ({location, pageContext, lang}) => {
  const [isLogoAnim, setLogoAnim] = useState(false);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isSubMobile, setSubMobile] = useState(-1);
  const [isInvert, setInvert] = useState(false);

  useEffect(() => {
    setSubMobile(-1);
  }, [isMenuVisible]);

  useEffect(() => {
    if (isInvert) {
      document.body.classList.add('invert');
    } else {
      document.body.classList.remove('invert');
    }
  }, [isInvert]);

  const getLinkWithLang = (lang) => {
    const arr = location.pathname.split('/');
    arr.splice(1, 1, lang);
    return arr.join('/');
  };

  const isPolish = lang === 'pl';

  const isArtist = location.pathname.includes('artist');

  const isAbout = location.pathname.includes('about');

  const isSubpage =
    (pageContext.title || location.pathname.includes('category')) &&
    !location.pathname.includes('arts');

  const isCategoryPage = location.pathname.includes('category');

  const isMainPage =
    !isSubpage && !isArtist && !isAbout && !location.pathname.includes('arts');

  const onLinkClick = () => {
    setMenuVisible(false);
  };

  const onSubMobileClick = (index) => {
    isSubMobile === index ? setSubMobile(-1) : setSubMobile(index);
  };

  return (
    <>
      <MobileHeaderContainer>
        <HamburgerMenu
          isOpen={isMenuVisible}
          menuClicked={() => setMenuVisible(!isMenuVisible)}
          width={45}
          height={28}
          strokeWidth={6}
          rotate={0}
          color="#d5d5d5"
          borderRadius={0}
          animationDuration={0.5}
        />
        <NavLink to={`/${lang}/`} style={{display: 'flex'}}>
          <NavLogo src={Logo} />
        </NavLink>
        {isMenuVisible && (
          <MobileMenuWrapper>
            <MobileMenuButton onClick={() => onSubMobileClick(2)}>
              <span>{isPolish ? 'O MUZEUM' : 'MUSEUM'}</span>
              {isSubMobile === 2 ? <ArrowUp /> : <ArrowDown />}
            </MobileMenuButton>
            {isSubMobile === 2 && (
              <MobileSubMenu>
                <MobileSubMenuLink
                  to={`/${lang}/about-1`}
                  onClick={onLinkClick}
                  color="#a3c0c0">
                  {isPolish ? 'CEL MUZEUM' : "MUSEUM'S MISSION"}
                </MobileSubMenuLink>
                <MobileSubMenuLink
                  to={`/${lang}/about-2`}
                  onClick={onLinkClick}
                  color="#e09087">
                  {isPolish ? 'TWÓRCY MUZEUM' : 'MUSEUM CREATORS'}
                </MobileSubMenuLink>
                <MobileSubMenuLink
                  to={`/${lang}/about-3`}
                  onClick={onLinkClick}
                  color="#e0c887">
                  {isPolish ? 'PATRONATY I PARTNERZY' : 'PARTNERS AND PATRONS'}
                </MobileSubMenuLink>
                <MobileSubMenuLink
                  to={`/${lang}/about-4`}
                  onClick={onLinkClick}
                  color="#afa3c0">
                  {isPolish ? 'BIBLIOGRAFIA' : 'BIBLIOGRAPHY'}
                </MobileSubMenuLink>
              </MobileSubMenu>
            )}
            <MobileMenuButton onClick={() => onSubMobileClick(1)}>
              <span>{isPolish ? 'O ARTYŚCIE' : 'ARTIST'}</span>
              {isSubMobile === 1 ? <ArrowUp /> : <ArrowDown />}
            </MobileMenuButton>
            {isSubMobile === 1 && (
              <MobileSubMenu>
                <MobileSubMenuLink
                  to={`/${lang}/artist-1`}
                  onClick={onLinkClick}
                  color="#a3c0c0">
                  <span>{isPolish ? 'ŻYCIORYS' : 'BIOGRAPHY'}</span>
                </MobileSubMenuLink>
                <MobileSubMenuLink
                  to={`/${lang}/artist-2`}
                  onClick={onLinkClick}
                  color="#e09087">
                  {isPolish ? 'RODZINA' : 'FAMILY'}
                </MobileSubMenuLink>
                <MobileSubMenuLink
                  to={`/${lang}/artist-3`}
                  onClick={onLinkClick}
                  color="#e0c887">
                  1925
                </MobileSubMenuLink>
                <MobileSubMenuLink
                  to={`/${lang}/artist-4`}
                  onClick={onLinkClick}
                  color="#afa3c0">
                  {isPolish ? 'WILLA WALERIA' : 'THE WALERIA VILLA'}
                </MobileSubMenuLink>
              </MobileSubMenu>
            )}
            <MobileMenuButton onClick={() => onSubMobileClick(0)}>
              <span>{isPolish ? 'DZIEŁA' : 'ARTWORK'}</span>
              {isSubMobile === 0 ? <ArrowUp /> : <ArrowDown />}
            </MobileMenuButton>
            {isSubMobile === 0 && (
              <MobileSubMenu>
                <MobileSubMenuLink
                  to={`/${lang}/category-1`}
                  onClick={onLinkClick}
                  color="#a3c0c0">
                  {isPolish
                    ? 'PRZEDSTAWIENIE POSTACI'
                    : 'DEPICTION OF THE FIGURES'}
                </MobileSubMenuLink>
                <MobileSubMenuLink
                  to={`/${lang}/category-2`}
                  onClick={onLinkClick}
                  color="#e09087">
                  {isPolish
                    ? 'MARSZAŁEK JÓZEF PIŁSUDSKI'
                    : 'MARSHALL JÓZEF PIŁSUDSKI'}
                </MobileSubMenuLink>
                <MobileSubMenuLink
                  to={`/${lang}/category-3`}
                  onClick={onLinkClick}
                  color="#e0c887">
                  {isPolish ? 'PŁASKORZEŹBY' : 'RELIEFS'}
                </MobileSubMenuLink>
                <MobileSubMenuLink
                  to={`/${lang}/category-4`}
                  onClick={onLinkClick}
                  color="#afa3c0">
                  {isPolish ? 'POZOSTAŁE DZIEŁA' : 'OTHER WORKS'}
                </MobileSubMenuLink>
              </MobileSubMenu>
            )}
            <MobileMenuLink to={`/${lang}/#contact`} onClick={onLinkClick}>
              {isPolish ? 'KONTAKT' : 'CONTACT'}
            </MobileMenuLink>
            <MobileMenuButton
              onClick={(e) => e.preventDefault()}
              style={{fontSize: '1rem', borderTop: '1px solid #5e5e5e'}}>
              <span>JĘZYK / LANGUAGE</span>
              <div>
                <Language to={getLinkWithLang('pl')} active={lang === 'pl'}>
                  PL
                </Language>
                <Separator>|</Separator>
                <Language to={getLinkWithLang('en')} active={lang === 'en'}>
                  EN
                </Language>
              </div>
            </MobileMenuButton>
          </MobileMenuWrapper>
        )}
      </MobileHeaderContainer>
      <HeaderContainer>
        {/* <ControlsContainer><SearchIcon src={Search} /></ControlsContainer> */}
        <NavContainer>
          <NavList>
            <NavElement>
              <NavLink to={`/${lang}/artist-1`}>
                {isPolish ? 'O ARTYŚCIE' : 'ARTIST'}
              </NavLink>
            </NavElement>
            <NavElement>
              <NavLink to={`/${lang}/about-1`}>
                {isPolish ? 'O MUZEUM' : 'MUSEUM'}
              </NavLink>
            </NavElement>
            <NavElement style={{width: '164px'}}>
              <NavLink to={`/${lang}/`}>
                <LogoContainer>
                  <div
                    onMouseEnter={() => setLogoAnim(true)}
                    onMouseLeave={() => {
                      setLogoAnim(false);
                    }}>
                    <Lottie
                      options={defaultOptions}
                      height={122}
                      width={122}
                      isStopped={!isLogoAnim}
                    />
                  </div>
                </LogoContainer>
              </NavLink>
            </NavElement>
            <NavElement>
              <NavLink to={`/${lang}/arts`}>
                {isPolish ? 'DZIEŁA' : 'ARTWORKS'}
              </NavLink>
            </NavElement>

            <NavElement>
              <NavLink to={`/${lang}/#contact`}>
                {isPolish ? 'KONTAKT' : 'CONTACT'}
              </NavLink>
            </NavElement>
          </NavList>
        </NavContainer>
        <ControlsContainer>
          <Language to={getLinkWithLang('pl')} active={lang === 'pl'}>
            PL
          </Language>
          <Separator>|</Separator>
          <Language to={getLinkWithLang('en')} active={lang === 'en'}>
            EN
          </Language>
          <ThemeIcon src={WheelChair} onClick={() => setInvert(!isInvert)} />
        </ControlsContainer>
      </HeaderContainer>
      {isMainPage && <CategoryArtist location={location} lang={lang} />}
      {/*{isSubpage && !isArtist && !isAbout && (*/}
      {/*  <StaticQuery*/}
      {/*    query={graphql`*/}
      {/*      query {*/}
      {/*        api {*/}
      {/*          arts {*/}
      {/*            title {*/}
      {/*              pl*/}
      {/*              en*/}
      {/*            }*/}
      {/*            titlePage*/}
      {/*            photos*/}
      {/*            category*/}
      {/*            models {*/}
      {/*              desktop*/}
      {/*              mobile*/}
      {/*            }*/}
      {/*          }*/}
      {/*        }*/}
      {/*      }*/}
      {/*    `}*/}
      {/*    render={(data) => (*/}
      {/*      <CategoryArts*/}
      {/*        isSubpage={isSubpage}*/}
      {/*        isCategoryPage={isCategoryPage}*/}
      {/*        location={location}*/}
      {/*        data={data.api.arts}*/}
      {/*        lang={lang}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  />*/}
      {/*)}*/}
      {isArtist && (
        <CategoryArtist
          isSubpage
          isCategoryPage
          location={location}
          lang={lang}
        />
      )}
      {isAbout && (
        <CategoryAbout
          isSubpage
          isCategoryPage
          location={location}
          lang={lang}
        />
      )}
    </>
  );
};

export default Header;
