import styled from 'styled-components';
import {Link} from 'gatsby';
import CustomLink from '../../../atoms/CustomLink';

export const FooterWrapper = styled.footer`
  background: #525252;
  width: 100%;
  height: 11rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

export const Partners = styled.div`
  display: flex;
  align-items: center;
  //justify-content: space-evenly;
  width: auto;
`;

export const PartnerLogo = styled.img`
  width: 3rem;
  margin-right: 1rem;
`;

export const PartnerText = styled.p`
  font-size: 0.6rem;
  color: white;
  font-family: 'LATOREGULAR';
  width: auto;
`;

export const ShareIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 10rem;
  margin: 1rem 0;
`;

export const IconLogo = styled.img`
  width: 2rem;
  cursor: pointer;
`;

export const CopyrightText = styled.p`
  font-family: 'LATOREGULAR';
  color: #b7b7b7;
  font-size: 0.8rem;
`;

export const RainbowLine = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 15px;
  width: 100%;
  display: flex;
`;

export const ColorLine = styled.div`
  width: 25%;
  height: 100%;
  background: ${({color}) => color};

  @media (max-width: 1024px) {
    display: ${({noMobile}) => noMobile && 'none'};
  }
`;

export const IconLink = styled(CustomLink)`
  svg {
    width: 2rem;
  }
  &:hover {
    svg path {
      fill: #fff;
    }
  }
`;
