/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect, useState} from 'react';
import 'focus-visible/dist/focus-visible.js';
import {TransitionProvider, TransitionViews} from 'gatsby-plugin-transitions';
import {ParallaxProvider} from 'react-scroll-parallax';

import Header from './header';
import './layout.css';
import Footer from './footer';

const Layout = ({location, children, pageContext}) => {
  const [lang, setLang] = useState('pl');
  useEffect(() => {
    setLang(location.pathname.split('/')[1].toLowerCase());

    if (
      location.pathname.includes('artist') ||
      location.pathname.includes('category')
    ) {
      document.body.querySelector('main').style.marginTop = '-8px';
    } else {
      document.body.querySelector('main').style.marginTop = '';
    }
  }, [location]);

  return (
    <ParallaxProvider>
      <Header location={location} pageContext={pageContext} lang={lang} />
      <main>{children}</main>
      <Footer />
    </ParallaxProvider>
  );
};

export default Layout;
