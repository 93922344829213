import React from 'react';
import SubCategory from './SubCategory';
import {RainbowContainer, ColorLine} from './styles';

const CategoryArts = ({isSubpage, isCategoryPage, location, lang}) => (
  <>
    <RainbowContainer>
      <ColorLine
        color="#a3c0c0"
        isSubpage={isSubpage}
        isRoundedRight={location.pathname.includes('/artist-2')}>
        {isSubpage && (
          <>
            <SubCategory
              to={`/${lang}/artist-1`}
              name={lang === 'pl' ? 'ŻYCIORYS' : 'BIOGRAPHY'}
              color="#a3c0c0"
              isCategoryPage={isCategoryPage}
              isRoundedRight={location.pathname.includes('/artist-2')}
            />
          </>
        )}
      </ColorLine>
      <ColorLine
        color="#e09087"
        isSubpage={isSubpage}
        isRoundedLeft={location.pathname.includes('/artist-1')}>
        {isSubpage && (
          <>
            <SubCategory
              to={`/${lang}/artist-2`}
              name={lang === 'pl' ? 'RODZINA' : 'FAMILY'}
              color="#e09087"
              isCategoryPage={isCategoryPage}
              isRoundedLeft={location.pathname.includes('/artist-1')}
            />
          </>
        )}
      </ColorLine>
      <ColorLine color="gray" style={{width: '164px'}} noMobile />
      <ColorLine
        color="#e0c887"
        isSubpage={isSubpage}
        isRoundedRight={location.pathname.includes('/artist-4')}>
        {isSubpage && (
          <>
            <SubCategory
              to={`/${lang}/artist-3`}
              name="1925"
              color="#e0c887"
              isCategoryPage={isCategoryPage}
              isRoundedRight={location.pathname.includes('/artist-4')}
            />
          </>
        )}
      </ColorLine>
      <ColorLine
        color="#afa3c0"
        isSubpage={isSubpage}
        isRoundedLeft={location.pathname.includes('/artist-3')}>
        {isSubpage && (
          <>
            <SubCategory
              to={`/${lang}/artist-4`}
              name={lang === 'pl' ? 'WILLA WALERIA' : 'THE WALERIA VILLA'}
              isCategoryPage={isCategoryPage}
              color="#afa3c0"
              isRoundedLeft={location.pathname.includes('/artist-3')}
            />
          </>
        )}
      </ColorLine>
    </RainbowContainer>
  </>
);

export default CategoryArts;
