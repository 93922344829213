import React, {useState} from 'react';
import {
  SubCategoryList,
  SubCategoryListName,
  SubCategoryWrapper,
  ElementList,
  Icon3D,
} from './styles';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const SubCategory = ({
  name,
  color,
  colorSlider,
  to,
  isCategoryPage,
  isRoundedLeft,
  isRoundedRight,
  data,
  lang,
}) => {
  const [isListVisible, setListVisible] = useState(false);

  const isObject3D = (path) => path.includes('.glb');
  return (
    <>
      <SubCategoryWrapper
        color={color}
        onMouseEnter={() => setListVisible(true)}
        onMouseLeave={() => setListVisible(false)}
        to={to}
        isRoundedLeft={isRoundedLeft}
        isRoundedRight={isRoundedRight}>
        {name}
      </SubCategoryWrapper>
      {isListVisible && !isCategoryPage && (
        <SubCategoryList
          color={colorSlider}
          onMouseEnter={() => setListVisible(true)}
          onMouseLeave={() => setListVisible(false)}>
          <SimpleBar style={{maxHeight: '76vh', paddingRight: '2rem'}}>
            {data.map((el) => (
              <ElementList>
                {isObject3D(el.models.desktop) && <Icon3D />}
                <SubCategoryListName to={`/${lang}/${el.titlePage}`}>
                  {el.title[lang]}
                </SubCategoryListName>
              </ElementList>
            ))}
          </SimpleBar>
        </SubCategoryList>
      )}
    </>
  );
};

//WORK IN PROGRESS

export default SubCategory;
