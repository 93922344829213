import AniLink from 'gatsby-plugin-transition-link/AniLink';
import React from 'react';

const CustomLink = (props) => (
  <AniLink to={props.to} cover bg="#525252" duration={0.75} {...props}>
    {props.children}
  </AniLink>
);

export default CustomLink;
