// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-about-1-js": () => import("./../../../src/pages/en/about-1.js" /* webpackChunkName: "component---src-pages-en-about-1-js" */),
  "component---src-pages-en-about-2-js": () => import("./../../../src/pages/en/about-2.js" /* webpackChunkName: "component---src-pages-en-about-2-js" */),
  "component---src-pages-en-about-3-js": () => import("./../../../src/pages/en/about-3.js" /* webpackChunkName: "component---src-pages-en-about-3-js" */),
  "component---src-pages-en-about-4-js": () => import("./../../../src/pages/en/about-4.js" /* webpackChunkName: "component---src-pages-en-about-4-js" */),
  "component---src-pages-en-artist-1-js": () => import("./../../../src/pages/en/artist-1.js" /* webpackChunkName: "component---src-pages-en-artist-1-js" */),
  "component---src-pages-en-artist-2-js": () => import("./../../../src/pages/en/artist-2.js" /* webpackChunkName: "component---src-pages-en-artist-2-js" */),
  "component---src-pages-en-artist-3-js": () => import("./../../../src/pages/en/artist-3.js" /* webpackChunkName: "component---src-pages-en-artist-3-js" */),
  "component---src-pages-en-artist-4-js": () => import("./../../../src/pages/en/artist-4.js" /* webpackChunkName: "component---src-pages-en-artist-4-js" */),
  "component---src-pages-en-arts-js": () => import("./../../../src/pages/en/arts.js" /* webpackChunkName: "component---src-pages-en-arts-js" */),
  "component---src-pages-en-category-1-js": () => import("./../../../src/pages/en/category-1.js" /* webpackChunkName: "component---src-pages-en-category-1-js" */),
  "component---src-pages-en-category-2-js": () => import("./../../../src/pages/en/category-2.js" /* webpackChunkName: "component---src-pages-en-category-2-js" */),
  "component---src-pages-en-category-3-js": () => import("./../../../src/pages/en/category-3.js" /* webpackChunkName: "component---src-pages-en-category-3-js" */),
  "component---src-pages-en-category-4-js": () => import("./../../../src/pages/en/category-4.js" /* webpackChunkName: "component---src-pages-en-category-4-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pl-about-1-js": () => import("./../../../src/pages/pl/about-1.js" /* webpackChunkName: "component---src-pages-pl-about-1-js" */),
  "component---src-pages-pl-about-2-js": () => import("./../../../src/pages/pl/about-2.js" /* webpackChunkName: "component---src-pages-pl-about-2-js" */),
  "component---src-pages-pl-about-3-js": () => import("./../../../src/pages/pl/about-3.js" /* webpackChunkName: "component---src-pages-pl-about-3-js" */),
  "component---src-pages-pl-about-4-js": () => import("./../../../src/pages/pl/about-4.js" /* webpackChunkName: "component---src-pages-pl-about-4-js" */),
  "component---src-pages-pl-artist-1-js": () => import("./../../../src/pages/pl/artist-1.js" /* webpackChunkName: "component---src-pages-pl-artist-1-js" */),
  "component---src-pages-pl-artist-2-js": () => import("./../../../src/pages/pl/artist-2.js" /* webpackChunkName: "component---src-pages-pl-artist-2-js" */),
  "component---src-pages-pl-artist-3-js": () => import("./../../../src/pages/pl/artist-3.js" /* webpackChunkName: "component---src-pages-pl-artist-3-js" */),
  "component---src-pages-pl-artist-4-js": () => import("./../../../src/pages/pl/artist-4.js" /* webpackChunkName: "component---src-pages-pl-artist-4-js" */),
  "component---src-pages-pl-arts-js": () => import("./../../../src/pages/pl/arts.js" /* webpackChunkName: "component---src-pages-pl-arts-js" */),
  "component---src-pages-pl-category-1-js": () => import("./../../../src/pages/pl/category-1.js" /* webpackChunkName: "component---src-pages-pl-category-1-js" */),
  "component---src-pages-pl-category-2-js": () => import("./../../../src/pages/pl/category-2.js" /* webpackChunkName: "component---src-pages-pl-category-2-js" */),
  "component---src-pages-pl-category-3-js": () => import("./../../../src/pages/pl/category-3.js" /* webpackChunkName: "component---src-pages-pl-category-3-js" */),
  "component---src-pages-pl-category-4-js": () => import("./../../../src/pages/pl/category-4.js" /* webpackChunkName: "component---src-pages-pl-category-4-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-templates-art-js": () => import("./../../../src/templates/art.js" /* webpackChunkName: "component---src-templates-art-js" */)
}

