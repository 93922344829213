import React from 'react';
import MCKLogo from '../../../images/mck.png';
import MKiDNLogo from '../../../images/MKiDN.png';
import FBLogo from '../../../images/icons/facebook.svg';
import InstaLogo from '../../../images/icons/instagram.svg';
import YTLogo from '../../../images/icons/youtube.svg';
import {
  FooterWrapper,
  Partners,
  PartnerLogo,
  PartnerText,
  ShareIcons,
  IconLogo,
  CopyrightText,
  RainbowLine,
  ColorLine,
  IconLink,
} from './styles';

const Footer = () => {
  return (
    <FooterWrapper>
      <Partners>
        <PartnerLogo src={MCKLogo} />
        <PartnerLogo src={MKiDNLogo} />
        <PartnerText>
          Dofinansowano <br /> ze środków <br /> Ministra Kultury <br /> i
          Dziedzictwa Narodowego.
        </PartnerText>
      </Partners>
      <ShareIcons>
        <IconLink to="https://www.facebook.com/muzeum.szczepkowskiego">
          <FBLogo />
        </IconLink>
        <IconLink to="https://www.instagram.com/muzeum.szczepkowskiego/">
          <InstaLogo />
        </IconLink>
        <IconLink to="https://www.youtube.com/channel/UCHfT0NabOoNPx2DR-BmsD8Q">
          <YTLogo />
        </IconLink>
      </ShareIcons>
      <CopyrightText>
        © 2020 Wirtualne Muzeum Jana Szczepkowskiego
      </CopyrightText>
      <RainbowLine>
        <ColorLine color="#a3c0c0" />
        <ColorLine color="#e09087" />
        <ColorLine color="#e0c887" />
        <ColorLine color="#afa3c0" />
      </RainbowLine>
    </FooterWrapper>
  );
};

export default Footer;
