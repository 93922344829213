import styled, {keyframes, css} from 'styled-components';
import {Link} from 'gatsby';
import SVG3D from '../../../../images/icons/icon3d.svg';
import CustomLink from '../../../atoms/CustomLink';

export const HeaderContainer = styled.header`
  width: 100%;
  /* padding: 0 1rem; */
  height: 102px;
  background: #525252;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 100;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const NavContainer = styled.nav`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  padding-left: 5.55rem;
  /* padding: 0 2rem; */

  //@media (max-width: 1600px) {
  //  width: 75%;
  //}
  //
  //@media (max-width: 1336px) {
  //  width: 70%;
  //}
  //
  //@media (max-width: 1168px) {
  //  width: 75%;
  //}
`;

export const NavList = styled.ul`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavElement = styled.li`
  display: inline-block;
  width: 25%;
  text-align: center;
`;

export const NavLink = styled(CustomLink)`
  text-decoration: none;
  color: #d5d5d5;
  cursor: pointer;
  font-family: 'RENNERBLACK', sans-serif;
  letter-spacing: 0.68px;
  font-size: 25px;

  &:hover {
    color: white;
  }
`;

export const LogoContainer = styled.div`
  margin-top: 68px;
  position: relative;
  padding: 26px 21px 10px;
  top: 0;
  /* transform: translateX(-50%); */
  z-index: 1;
  background-color: #525252;
  border-radius: 12px;
  padding-bottom: 21px;

  &::before {
    content: '';
    position: absolute;
    background-color: transparent;
    top: 101px;
    left: -15px;
    height: 20px;
    width: 15px;
    border-top-right-radius: 25px;
    box-shadow: 3px -5px 0 2px #525252;
  }

  &::after {
    content: '';
    position: absolute;
    background-color: transparent;
    top: 101px;
    right: -15px;
    height: 20px;
    width: 15px;
    border-top-left-radius: 25px;
    box-shadow: -3px -5px 0 2px #525252;
  }
`;

export const NavLogo = styled.img`
  height: 122px;
  width: auto;
  position: absolute;

  @media (max-width: 1024px) {
    height: 50px;
    width: auto;
    position: relative;
  }
`;

export const SearchIcon = styled.img`
  width: auto;
  height: 26px;
  cursor: pointer;
`;

export const Language = styled(CustomLink)`
  color: ${({active}) => (active ? '#ffffff' : '#d5d5d5')};
  font-family: LATOBLACK;
  font-size: 17px;
  letter-spacing: 0.76px;
  cursor: pointer;
  display: inline;
  text-decoration: none;

  &:not(:last-of-type) {
    margin-right: 0.2rem;
  }

  &:hover {
    color: #ffffff;
  }
`;

export const Separator = styled.span`
  color: #d5d5d5;
  font-family: LATOBLACK;
  font-size: 17px;
  letter-spacing: 0.76px;
  margin-right: 0.2rem;
`;

export const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 26px;
  width: 6rem;
`;

export const ThemeIcon = styled.img`
  width: auto;
  height: 26px;
  cursor: pointer;
  margin-left: 1rem;
`;

const slideBarAnim = keyframes`
  0%{
    padding-bottom: 0;
  }
  100%{
    padding-bottom: 67px;
  }
`;

const enterAnim = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;

const barStyle = css`
  animation: ${slideBarAnim} 1s 0.5s forwards;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: 'LATOBLACK';
  font-size: 0.7rem;
  white-space: nowrap;
  font-weight: 400;
  position: relative;

  & > a {
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ${enterAnim} 1s 1s forwards;
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
    height: 100%;
    width: 100%;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SubCategoryWrapper = styled(Link)`
  position: relative;
  width: 100%;
  text-align: center;
  background-color: ${({color}) => color};
  border-bottom-left-radius: ${({isRoundedLeft}) => isRoundedLeft && '12px'};
  border-bottom-right-radius: ${({isRoundedRight}) => isRoundedRight && '12px'};
`;

const slideDown = keyframes`
  0%{
    transform: translateY(-100%);
  }
  100%{
    transform: translateY(0);
  }
`;

export const SubCategoryList = styled.div`
  background: ${({color}) => color};
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 1rem 2rem;
  padding-right: 0;
  max-height: 76vh;
  overflow-y: hidden;
  animation: ${slideDown} 0.7s forwards;
  padding-left: 0;
`;

export const SubCategoryListName = styled(CustomLink)`
  font-family: 'LATOREGULAR';
  display: block;
  color: #fff;
  font-size: 0.7rem;
  padding: 0.5rem 0;
  opacity: 0.7;
  white-space: normal;
  cursor: pointer;
  text-decoration: none;
`;

export const ColorLine = styled.div`
  width: 25%;
  height: 15px;
  background: ${({color}) => color};

  @media (max-width: 1024px) {
    height: 10px;
    display: ${({noMobile}) => noMobile && 'none'};
    & > a {
      display: none;
    }
  }
  @media (min-width: 1024px) {
    ${({isSubpage}) => isSubpage && barStyle};

    border-bottom-left-radius: ${({isRoundedLeft}) => isRoundedLeft && '12px'};
    border-bottom-right-radius: ${({isRoundedRight}) =>
      isRoundedRight && '12px'};
  }
`;

export const RainbowContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;

  @media (max-width: 1024px) {
    position: fixed;
    top: 9vh;
    left: 0;
    z-index: 20;
    height: 1.4vh;
  }
`;

export const MobileHeaderContainer = styled.div`
  width: 100%;
  padding: 0.5rem 1rem;
  background: #525252;
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    height: 9vh;
    z-index: 20;
  }
`;

export const MobileMenuWrapper = styled.div`
  background: #525252;
  position: absolute;
  top: 74px;
  left: 0;
  width: 100vw;
  z-index: 1;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
`;

export const MobileMenuLink = styled(CustomLink)`
  padding: 1rem 2rem;
  color: #d5d5d5;
  font-family: RENNERBLACK;
  font-size: 2rem;
  text-decoration: none;
`;

export const MobileMenuButton = styled.div`
  padding: 1rem 2rem;
  color: #d5d5d5;
  font-family: RENNERBLACK;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-top: 1rem solid #d5d5d5;
`;

export const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid #d5d5d5;
`;

const animDown = keyframes`
  0%{
    padding: 0rem 3rem;
    line-height: 0;
  }
  100%{
    padding: 1rem 3rem;
    line-height: 1.3;
  }
`;

const animUp = keyframes`
  0%{
    padding: 1rem 3rem;
    line-height: 1.3;
  }
  100%{
    padding: 0rem 3rem;
    line-height: 0;
  }
`;

export const MobileSubMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileSubMenuLink = styled(CustomLink)`
  padding: 0rem 3rem;
  line-height: 0;
  color: #ffffff;
  font-family: LATOBLACK;
  font-size: 1.5rem;
  background: ${({color}) => color};
  text-decoration: none;
  animation: ${animDown} 0.5s forwards ease-out;
`;

export const Icon3D = styled(SVG3D)`
  position: absolute;
  left: 0;
  width: 1rem;
  top: 50%;
  transform: translate(50%, -43%);
`;

export const ElementList = styled.div`
  position: relative;
  padding-left: 2rem;
  cursor: pointer;

  &:hover {
    a {
      opacity: 1;
    }

    svg {
      path {
        fill: white;
      }
    }
  }
`;
